import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import Heading from "../../components/Layout/Heading"
import Content from "../../components/Layout/Content"

const Page = ({ location, pageContext }) => {
  const { page } = pageContext

  return (
    <Layout location={location}>
      <SEO title={page.title} description={page.description} />
      <Heading title={page.title} />
      <Content content={page.content} />
    </Layout>
  )
}

export default Page
